import React, {useState, useEffect} from 'react'
import { GetCostoManoDeObra } from './dbfirebase';
import { MdoModal } from './modalMDO'

export const ListaManodeObra = () =>{

    const [data, setData] = useState()
    const [loading, setLoading] = useState(true);
    const [activeDate, setActiveDate] = useState(null);

    const getting = async () =>{
        const dato = await GetCostoManoDeObra()
        setData(dato[0])
        setLoading(false);
    }

    useEffect(() =>{
        getting()
    },[])

    // Manejar la apertura y cierre del modal
    const toggleModal = () => {
        console.log("agregar")
    };
    
     // Función para transformar los datos en un formato adecuado para la tabla
     const transformData = (data) => {
        const rowsByDate = {};
        for (const date in data) {
            const items = data[date][0]; // Asumiendo que siempre es un array de un solo objeto
            for (const item in items) {
                if (!rowsByDate[date]) {
                    rowsByDate[date] = [];
                }
                rowsByDate[date].push({
                    name: item,
                    cost: items[item]
                });
            }
        }
        return rowsByDate;
    };
   
    const tableData = data ? transformData(data) : [];

    // Obtener todas las fechas disponibles
    const dates = Object.keys(tableData);
    
    // Si no hay fecha activa, seleccionar la más reciente por defecto
    useEffect(() => {
        if (dates.length > 0 && !activeDate) {
            setActiveDate(dates[dates.length - 1]); // Selecciona la fecha más reciente
        }
    }, [dates, activeDate]);
    
    return (
        <div>
            <h1 style={{marginBottom:"2rem"}}>Lista de Mano de Obra</h1>
            {tableData && <MdoModal data = {tableData[activeDate]}/>}
            {loading ? (
                <div className="spinner">
                    <p>Cargando...</p>
                </div>
            ) : (
                <>
                    <div className="row">
                        <div>
                            <ul className="nav nav-tabs flex-fill" role="tablist">
                                {dates.map((date, index) => (
                                    <li className="nav-item" key={index}>
                                        <button
                                            className={`nav-link ${activeDate === date ? 'active' : ''}`}
                                            id={`tab-${index}`}
                                            data-bs-toggle="tab"
                                            data-bs-target={`#tab-content-${index}`}
                                            type="button"
                                            role="tab"
                                            aria-controls={`tab-content-${index}`}
                                            aria-selected={activeDate === date}
                                            onClick={() => setActiveDate(date)}
                                        >
                                            {date}
                                        </button>
                                    </li>
                                ))}
                                <li className="nav-item">
                                    {/* Botón "+" para abrir el modal */}
                                    <button
                                        className="nav-link"
                                        // onClick={toggleModal}
                                        type="button"
                                        style={{ fontSize: '24px', padding: '0 10px' }}
                                        data-bs-toggle="modal" 
                                        data-bs-target="#ModalMDO"
                                    >
                                        +
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content mt-3">
                            {dates.map((date, index) => (
                                <div
                                    key={index}
                                    className={`tab-pane ${activeDate === date ? 'active' : ''}`}
                                    id={`tab-content-${index}`}
                                    role="tabpanel"
                                    aria-labelledby={`tab-${index}`}
                                    style={{ marginBottom: '20px', marginTop: '20px' }}
                                >
                                    {activeDate === date && (
                                        <div>
                                            <h2>{date}</h2> {/* Mostrar la fecha activa como encabezado */}
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Nombre del Servicio</th>
                                                        <th>Costo</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableData[date].map((row, rowIndex) => (
                                                        <tr key={rowIndex}>
                                                            <td>{row.name}</td>
                                                            <td>{row.cost}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}